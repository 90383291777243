import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import { CarouselPaginationDots } from '../../atoms/Carousel/styles';

export const TeamList = styled.div`
  ${CarouselPaginationDots} {
    margin-top: 3rem;
  }

  @media ${media.phone} {
    .slider {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
      margin-top: -3rem;
    }
  }
`;

export const TeamListSlide = styled.div`
  @media ${media.phone} {
    width: calc(50% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 3rem;
  }
  @media ${media.tablet} {
    width: calc(100% / 3 - 3rem);
  }
`;
