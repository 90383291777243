import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { TeamListProps } from './types';
import * as Styled from './styles';
import { settings } from './const';

import TeamTile from '../../atoms/TeamTile';

const TeamList: React.FC<TeamListProps> = ({ items }) => (
  <Styled.TeamList>
    <Slider {...settings}>
      {items.map(({ key, title, subtitle, description, link, image }) => (
        <Styled.TeamListSlide {...{ key }}>
          <TeamTile {...{ title, subtitle, description, link, image }} />
        </Styled.TeamListSlide>
      ))}
    </Slider>
  </Styled.TeamList>
);

export default TeamList;
