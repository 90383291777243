import styled from 'styled-components';

import { Inner, Box } from '../../styles/Grid/styles';
import media from '../../../common/MediaQueries';

export const TeamInner = styled(Inner)``;

export const TeamList = styled(Box)`
  margin-top: 3rem;

  @media ${media.phone} {
    margin-top: 5rem;
  }
`;
