import { graphql } from 'gatsby';
import React from 'react';
import { getHref, getImage } from '../../../../utils/sanityTypes';

import { TeamListItemProps } from '../../../molecules/TeamList/types';
import Team from '../../Team';
import { TeamSectionProps } from './types';

const TeamSection: React.VFC<TeamSectionProps> = ({ title, items }) => (
  <Team
    data={{
      title: title ?? '',
      items: (items ?? []).map<TeamListItemProps>((person) => {
        const { _key: key, title: personTitle, subtitle, description, link, image } = person ?? {};

        return {
          key: key ?? '',
          title: personTitle ?? '',
          subtitle: subtitle ?? '',
          description: description ?? undefined,
          link: { to: getHref(link) },
          image: getImage(image),
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment TeamSectionFragment on SanityTeamSection {
    _key
    _type
    title
    items {
      _key
      title
      subtitle
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      link {
        ...LinkFragment
      }
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default TeamSection;
