import styled from 'styled-components';
import { rgba } from 'polished';

import { FlexBox } from '../../styles/Grid/styles';
import Link from '../Link';
import media from '../../../common/MediaQueries';
import Typography from '../../styles/Typography';
import { positionFullStyles, transition } from '../../../common/mixins';

export const TeamTileContent = styled(FlexBox)`
  ${transition('all', '300ms')}
  position: relative;
  justify-content: space-between;
  padding: 2.5rem;
  background-color: ${({ theme }) => theme.colors.neutral.neutral10};
  height: 100%;
  max-height: 8.25rem;
  z-index: 1;

  @media ${media.phone} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const TeamTileContentHidden = styled(FlexBox)`
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;

  @media ${media.phone} {
    flex-grow: 1;
    margin-top: 1rem;
    margin-left: 0;
    opacity: 0;
    max-height: 0;
  }
`;

export const TeamTileInfo = styled.div``;

export const TeamTileTitle = styled(Typography)`
  ${transition()}
`;

export const TeamTileSubtitle = styled(Typography)`
  ${transition()}
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const TeamTileDesc = styled.div`
  ${transition()}
  margin-top: 0.875rem;
  color: ${({ theme }) => theme.colors.neutral.neutral10};
  max-height: 18.25rem;
  overflow: auto;

  @media ${media.maxPhone} {
    display: none;
  }
`;

export const TeamTileMedia = styled.div`
  ${transition()}
  ${positionFullStyles}
  max-height: calc(100% - 2.25rem);
`;

export const TeamTileAction = styled(Link)`
  ${transition('all', '280ms')}
  transition-delay: 360ms;
  color: ${({ theme }) => theme.colors.additional.accentGray1};

  @media ${media.phone} {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const TeamTile = styled(FlexBox)`
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  height: 32.5rem;
  border: 1px solid ${({ theme }) => theme.colors.additional.accentGray3};
  overflow: hidden;

  @media ${media.phone} {
    &:hover {
      ${TeamTileContent} {
        max-height: 100%;
        background-color: ${({ theme }) => rgba(theme.colors.secondary.hover, 0.9)};
      }
      ${TeamTileContentHidden} {
        opacity: 1;
      }
      ${TeamTileContentHidden},
      ${TeamTileMedia} {
        max-height: 100%;
      }
      ${TeamTileTitle},
      ${TeamTileSubtitle} {
        color: ${({ theme }) => theme.colors.neutral.neutral10};
      }
      ${TeamTileAction} {
        transform: translateY(0);
        opacity: 1;
      }
      ${TeamTileAction}:hover {
        opacity: 0.8;
      }
    }
  }
`;
