import React from 'react';

import { TeamTileProps } from './types';
import * as Styled from './styles';

import { IconEmail } from '../../../assets/svg';
import Image from '../Image';

import { LinkMode } from '../Link/enums';
import RichText from '../../molecules/RichText';

const TeamTile: React.FC<TeamTileProps> = ({ title, subtitle, description, link, image }) => (
  <Styled.TeamTile>
    <Styled.TeamTileContent>
      <Styled.TeamTileInfo>
        <Styled.TeamTileTitle variant="textL">{title}</Styled.TeamTileTitle>
        {subtitle && <Styled.TeamTileSubtitle variant="textM">{subtitle}</Styled.TeamTileSubtitle>}
      </Styled.TeamTileInfo>
      <Styled.TeamTileContentHidden>
        {description && (
          <Styled.TeamTileDesc>
            <RichText hasDarkBackground data={description} />
          </Styled.TeamTileDesc>
        )}
        {link && (
          <Styled.TeamTileAction {...link} mode={LinkMode.HasPlainLook}>
            <IconEmail />
          </Styled.TeamTileAction>
        )}
      </Styled.TeamTileContentHidden>
    </Styled.TeamTileContent>
    <Styled.TeamTileMedia>
      <Image {...image} />
    </Styled.TeamTileMedia>
  </Styled.TeamTile>
);

export default TeamTile;
