import React from 'react';

import * as Styled from './styles';
import { TeamProps } from './types';

import Heading from '../../molecules/Heading';
import TeamList from '../../molecules/TeamList';

const Team: React.FC<TeamProps> = ({ data }) => {
  const { title, items } = data;

  return (
    <Styled.TeamInner>
      <Heading {...{ title }} />
      <Styled.TeamList>
        <TeamList {...{ items }} />
      </Styled.TeamList>
    </Styled.TeamInner>
  );
};

export default Team;
