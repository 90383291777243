import React from 'react';
import { Settings as CarouselSettings } from 'react-slick';

import * as Styled from '../../atoms/Carousel/styles';

export const settings: CarouselSettings = {
  arrows: false,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  infinite: true,
  speed: 1000,
  fade: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        dots: true,
      },
    },
    {
      breakpoint: 10000,
      settings: 'unslick',
    },
  ],
  appendDots: (dots: React.ReactNode) => (
    <Styled.CarouselPaginationDots>{dots}</Styled.CarouselPaginationDots>
  ),
  customPaging: () => <Styled.CarouselPagination />,
};
